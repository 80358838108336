
import { defineComponent, ref ,reactive} from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from 'ant-design-vue';
export default defineComponent({
    emit: ['downloadAction','closeAction'],
    props: {
        downloadVisible:{
            required: true,
            type:Boolean
        },
        language:{
            required: false,
            type:String,
            default:'CN'
        },
        downloadTableData: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
    },
  setup(props,{emit}) {
      type Key = ColumnProps['key'];
      const cancelButton = (props as any).language == 'EN'?'Cancel':'取消'
      const confirmButton = (props as any).language == 'EN'?'Download':'下载'

      const downloadColumnsData = ref<any[]>([])

      if ((props as any).language == 'EN'){
        downloadColumnsData.value = [{
            title: 'File',
            dataIndex: 'file',
            key: 'file',
          }]
      } else {
          downloadColumnsData.value = [          {
            title: '文件',
            dataIndex: 'file',
            key: 'file',
          }]

      }

    const downloadRowSelection = reactive<{
        selectedRowKeys: Key[];
        selectedRowData: any[];
        onChange: Function;
    }>({
        onChange: (selectedRowKeys: Key[], selectedRows: any[]) => {
            downloadRowSelection.selectedRowKeys = selectedRowKeys;
            downloadRowSelection.selectedRowData = selectedRows;
        },
        selectedRowData: [],
        selectedRowKeys: [],
    });

    const closeDownload =()=>{
        downloadRowSelection.selectedRowKeys = [];
        downloadRowSelection.selectedRowData = [];

        emit('close-action')
    }
    const downloadClick =()=>{
        if (downloadRowSelection.selectedRowData.length == 0 ){
            if ((props as any).language == 'EN'){
                message.warning('Please select data first')
            } else {
                message.warning('请先选择数据')
            }
            
            return
        }
        emit('download-action',downloadRowSelection.selectedRowData)
        downloadRowSelection.selectedRowKeys = [];
        downloadRowSelection.selectedRowData = [];
        
    }
    
    return {
        downloadClick,
        closeDownload,cancelButton,confirmButton,downloadColumnsData,downloadRowSelection
    };
  },
});
