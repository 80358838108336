
import {
    defineComponent,
    ref,
    watch,
    computed,
    onMounted,
    nextTick,
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import BuSelector from '@/views/SalesReporting/components/BuSelector.vue';
import {
    getCheckingRoundList,
    planActualRoundPage,
    planActualCategory,
    planActualExport,
} from '@/API/salesReporting/claimCheckingReport';
import { getOfferType } from '@/API/claim/delaerClaim/delaer';
import { qmOptions } from '@/views/SalesReporting/type';
import { TreeSelect } from 'ant-design-vue';
import { columnType } from './types';
import downloadFile from '@/utils/claim/downloadFile';
import DM from '@/views/SalesReporting/components/downloadModal.vue';

const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default defineComponent({
    components: {
        BuSelector,
        DM,
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
    },
    setup() {
        const spaceSize = 6;
        // 数据筛选维度
        const selectedDimension = ref(1);
        const dimensions = [
            {
                id: 1,
                title: 'Checking Round View',
            },
            {
                id: 2,
                title: 'Checking Round by Program Category',
            },
        ];
        // bu
        const bu = ref('');
        let buCopy = '';
        // (Payout Round) Checking Round
        // const payoutRound = ref<string | undefined>(undefined);
        // const payoutRoundList = ref([]);
        // const store = useStore();
        // const buNameIdMap = computed(() => {
        //     const buLst = store.state.user.buList.reduce(
        //         (maps: any, bu: any) => {
        //             return { ...maps, [bu.nameCn]: bu.id };
        //         },
        //         {}
        //     );
        //     return buLst;
        // });
        // const getCheckingRound = (buName: string) => {
        //     getCheckingRoundList({ buLst: buNameIdMap.value[buName] }).then(
        //         (res: any) => {
        //             payoutRoundList.value = res;
        //         }
        //     );
        // };
        // watch(bu, (newVal: string) => {
        //     getCheckingRound(newVal);
        // });

        // Program Catogroy
        const isProgramCategorySelectAll = ref(false);
        const programCategory = ref<string[]>([]);
        const programCategoryList = ref<any[]>([]);
        let allProgramCategoryList: any[];
        const programCategoryLength = ref(0);
        getOfferType().then((res: any): void => {
            allProgramCategoryList = res.incentiveCategory.reduce(
                (arr: any, ele: any) => {
                    return [...arr, ...ele.offerType];
                },
                []
            );
            programCategoryLength.value = allProgramCategoryList.length;
            programCategoryList.value = allProgramCategoryList;
        });
        watch(programCategory, (newVal: string[]) => {
            if (newVal.length === 0) {
                programCategoryList.value = allProgramCategoryList;
                isProgramCategorySelectAll.value = false;
            }
        });

        //Checking Round Period
        const checkingRoundPeriodYear = ref<string>(moment().format('YYYY'));
        const openStartPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openStartPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            checkingRoundPeriodYear.value = time as string;
            openStartPeriodYear.value = false;
        };

        const checkingRoundPeriodQM = ref<string[]>([`Q${moment().quarter()}`]);

        watch(checkingRoundPeriodYear, (newVal) => {
            if (!newVal) {
                checkingRoundPeriodQM.value = [];
            }
        });
        // 查询的数据
        const dataSource1 = ref<any[]>([]);
        const dataSource2 = ref<any[]>([]);
        // const columns = computed(() => {
        //     return columnType[selectedDimension.value];
        // });

        const colHeight = ref(500);
        const calculateHeight = () => {
            const table = document.getElementsByClassName(
                'ant-table-wrapper'
            )[0];
            const clinetHeight = document.body.clientHeight;
            const top = table.getBoundingClientRect().top;
            colHeight.value = clinetHeight - top - 100;
        };

        nextTick(() => {
            calculateHeight();
        });

        const downloadTableData = [
            {
                file: 'Raw Data',
                sign: 1,
            },
            {
                file: 'Report Data',
                sign: 2,
            },
        ];

        const reset = () => {
            bu.value = buCopy;
            // payoutRound.value = undefined;
            programCategory.value = [];
            checkingRoundPeriodYear.value = moment().format('YYYY');
            checkingRoundPeriodQM.value = [`Q${moment().quarter()}`];
        };

        const initValue = (value: string) => {
            bu.value = value;
            buCopy = value;
        };

        const genPeriod = () => {
            const programPeriod = {
                roundStartPeriodYear: '',
                roundStartPeriodMonth: [''],
            };
            const monthToQuarter = {
                '01': 1,
                '02': 1,
                '03': 1,
                '04': 2,
                '05': 2,
                '06': 2,
                '07': 3,
                '08': 3,
                '09': 3,
                '10': 4,
                '11': 4,
                '12': 4,
            };
            const quarterToMonth = {
                Q1: ['1-01', '1-02', '1-03'],
                Q2: ['2-04', '2-05', '2-06'],
                Q3: ['3-07', '3-08', '3-09'],
                Q4: ['4-10', '4-11', '4-12'],
            };
            const year = checkingRoundPeriodYear.value;
            const qmList = checkingRoundPeriodQM.value;
            const monthList = qmList.reduce((mthList: any, qm: string) => {
                if (qm in quarterToMonth) {
                    return [...mthList, ...quarterToMonth[qm]];
                } else {
                    return [...mthList, `${monthToQuarter[qm]}-${qm}`];
                }
            }, []);
            programPeriod.roundStartPeriodYear = year;
            programPeriod.roundStartPeriodMonth = monthList.map(
                (mthList: any) => {
                    return `${year}-${mthList}`;
                }
            );
            // console.log('programPeriod', programPeriod);
            return programPeriod;
        };

        const genParams = (downloadType: number | undefined = undefined) => {
            const tabs = {
                // downloadType_selectedDimension
                '1_1': 'RawData',
                '1_2': 'RawData',
                '2_1': 'CheckingRound',
                '2_2': 'ProgramCagegory',
                '3_1': 'CheckingRound&RawData',
                '3_2': 'ProgramCagegory&RawData',
            };
            return {
                bus: [bu.value],
                // checkingRoundId: payoutRound.value,
                current: 0,
                size: -1,
                tabName:
                    downloadType &&
                    tabs[`${downloadType}_${selectedDimension.value}`],
                programCagegoryId: programCategory.value,
                ...genPeriod(),
            };
        };

        const search = () => {
            dataSource2.value = [];
            const apis = {
                1: planActualRoundPage,
                2: planActualCategory,
            };
            const searchApi = apis[selectedDimension.value];
            const params = genParams();
            searchApi(params).then((res: any) => {
                if (selectedDimension.value === 1) {
                    dataSource1.value = res;
                } else {
                    dataSource2.value = res;
                }
            });
        };

        const downloadTypeVisible = ref(false);
        const download = () => {
            downloadTypeVisible.value = true;
        };
        const closeDownloadTypeModal = () => {
            downloadTypeVisible.value = false;
        };
        const handleOk = (selectedData: any) => {
            //TODO 下载需要再验证下前后端接口
            let downloadType;
            if (selectedData.length > 1) {
                downloadType = 3;
            } else {
                downloadType = selectedData[0].sign;
            }
            const downloadParams = genParams(downloadType);
            const params = {
                url: `/claimapi/checking/plan/actual/claim/checking/report/export`,
                method: 'post',
                params: downloadParams,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then(
                (res: any) => {
                    downloadTypeVisible.value = false;
                }
            );
        };

        const selectDimension = (e: Event) => {
            selectedDimension.value = parseInt(
                (e.target as HTMLInputElement).value
            );
            search();
        };

        // program category 全选
        const programCategorySelectAllOrNot = () => {
            isProgramCategorySelectAll.value = !isProgramCategorySelectAll.value;
            if (isProgramCategorySelectAll.value) {
                const allProgramCategory = programCategoryList.value.map(
                    (pg: any) => {
                        return pg.id;
                    }
                );
                programCategory.value = allProgramCategory;
            } else {
                programCategory.value = [];
            }
        };

        let timer: any;
        const fetching = ref(false);
        const remoteSearch = (val: string) => {
            fetching.value = true;
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(() => {
                programCategoryList.value = allProgramCategoryList.filter(
                    (pc: any) => {
                        return pc.nameEn.toLowerCase().indexOf(val) > -1;
                    }
                );
                fetching.value = false;
            }, 300);
        };

        const totalRowClass = (record: any) => {
            if (record.batchName === 'All') {
                return 'bold';
            }
        };

        onMounted(() => {
            search();
        });
        onMounted(() => {
            window.addEventListener('resize', calculateHeight);
        });

        return {
            spaceSize,
            selectedDimension,
            dimensions,
            bu,
            // payoutRound,
            // payoutRoundList,
            programCategory,
            programCategoryList,
            checkingRoundPeriodYear,
            checkingRoundPeriodQM,
            qmOptions,
            SHOW_PARENT,
            openStartPeriodYear,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
            dataSource1,
            dataSource2,
            reset,
            search,
            initValue,
            downloadTypeVisible,
            download,
            handleOk,
            closeDownloadTypeModal,
            selectDimension,
            downloadTableData,
            isProgramCategorySelectAll,
            programCategorySelectAllOrNot,
            remoteSearch,
            programCategoryLength,
            colHeight,
            fetching,
            columnType,
            totalRowClass,
        };
    },
});
