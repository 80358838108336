import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.downloadVisible,
      title: _ctx.language==='CN'? '下载' : 'Download',
      onCancel: _ctx.closeDownload
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "back",
          onClick: _ctx.closeDownload
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.cancelButton), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          key: "submit",
          type: "primary",
          onClick: _ctx.downloadClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.confirmButton), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          "row-selection": _ctx.downloadRowSelection,
          columns: _ctx.downloadColumnsData,
          pagination: false,
          dataSource: _ctx.downloadTableData,
          rowKey: "fileType"
        }, null, 8, ["row-selection", "columns", "dataSource"])
      ]),
      _: 1
    }, 8, ["visible", "title", "onCancel"])
  ]))
}