/*
 * @Author: zhengda.lv
 * @Date: 2021-11-01 16:52:30
 * @LastEditTime: 2021-12-29 14:22:01
 * @LastEditors: zhengda.lv
 * @Description:
 */
const categoryColumns = [
    {
        title: 'Checking Round',
        dataIndex: 'checkingRound',
        align: 'center',
        width: 150,
        key: '1',
    },
    {
        title: 'BU',
        dataIndex: 'bu',
        align: 'center',
        width: 100,
        key: '2',
    },
    {
        title: 'Program Category',
        dataIndex: 'programCagegory',
        align: 'center',
        width: 200,
        key: '3',
    },
    {
        title: 'Checking Plan',
        children: [
            {
                title: 'Program Volume',
                dataIndex: 'planProgramVolume',
                align: 'center',
                width: 150,
                key: '4',
            },
            {
                title: 'Program Code & Name',
                dataIndex: 'planProgramCodeAndName',
                align: 'center',
                key: '5',
            },
            {
                title: 'Claim No',
                dataIndex: 'planClaimNo',
                align: 'center',
                width: 100,
                key: '6',
            },
            {
                title: 'Estimated Claim Amount(Budget)',
                dataIndex: 'estimatedClaimAmountBudget',
                align: 'center',
                width: 300,
                key: '7',
            },
        ],
    },
    {
        title: 'Checking Actual',
        children: [
            {
                title: 'Program Volume',
                dataIndex: 'actualProgramVolume',
                align: 'center',
                width: 150,
                key: '8',
            },
            {
                title: 'Program Code',
                dataIndex: 'actualProgramCode',
                align: 'center',
                width: 200,
                key: '9',
            },
            {
                title: 'Checked Claim Volume',
                dataIndex: 'actualCheckedClaimVolume',
                align: 'center',
                width: 200,
                key: '10',
            },
            {
                title: 'Checked Claim Amount',
                dataIndex: 'actualCheckedClaimAmount',
                align: 'center',
                width: 200,
                key: '11',
            },
            {
                title: 'Communicate to Dealer',
                dataIndex: 'actualSentToDealer',
                align: 'center',
                width: 200,
                key: '12',
            },
        ],
    },
    {
        title: 'Gap(Uncheck)',
        children: [
            {
                title: 'Claim Volume Gap',
                dataIndex: 'gapClaimVolume',
                align: 'center',
                width: 150,
                key: '13',
            },
            {
                title: 'Gap Amount',
                dataIndex: 'gapAmount',
                align: 'center',
                width: 200,
                key: '14',
            },
        ],
    },
    {
        title: 'Ratio',
        children: [
            {
                title: 'Approved Rate',
                dataIndex: 'approvedRate',
                align: 'center',
                width: 150,
                key: '15',
            },
            {
                title: 'Rejected Rate',
                dataIndex: 'rejectedRate',
                align: 'center',
                width: 150,
                key: '16',
            },
            {
                title: 'Pending Rate',
                dataIndex: 'pendingRate',
                align: 'center',
                width: 150,
                key: '17',
            },
        ],
    },
];

const viewColumns = [
    {
        title: 'Checking Round',
        dataIndex: 'checkingRound',
        align: 'center',
        width: 150,
        key: '18',
    },
    {
        title: 'Checking Batch',
        dataIndex: 'batchName',
        align: 'center',
        width: 150,
        key: '19',
    },
    {
        title: 'BU',
        dataIndex: 'bu',
        align: 'center',
        width: 100,
        key: '20',
    },
    {
        title: 'Checking Plan',
        children: [
            {
                title: 'Claim Volume',
                dataIndex: 'planClaimVolume',
                align: 'center',
                width: 150,
                key: '21',
            },
            {
                title: 'Estimated Claim Amount(Budget)',
                dataIndex: 'estimatedClaimAmountBudget',
                align: 'center',
                width: 300,
                key: '22',
            },
        ],
    },
    {
        title: 'Checking Actual',
        children: [
            {
                title: 'Checked Claim Volume',
                dataIndex: 'actualCheckedClaimVolume',
                align: 'center',
                width: 200,
                key: '23',
            },
            {
                title: 'Checked Claim Amount',
                dataIndex: 'actualCheckedClaimAmount',
                align: 'center',
                width: 200,
                key: '24',
            },
            {
                title: 'Communicate to Dealer',
                dataIndex: 'actualSentToDealer',
                align: 'center',
                width: 200,
                key: '25',
            },
        ],
    },
    {
        title: 'Gap(Uncheck)',
        children: [
            {
                title: 'Claim Volume Gap',
                dataIndex: 'gapClaimVolume',
                align: 'center',
                width: 150,
                key: '26',
            },
            {
                title: 'Gap Amount',
                dataIndex: 'gapAmount',
                align: 'center',
                width: 200,
                key: '27',
            },
        ],
    },
    {
        title: 'Ratio',
        children: [
            {
                title: 'Approved Rate',
                dataIndex: 'approvedRate',
                align: 'center',
                width: 150,
                key: '28',
            },
            {
                title: 'Rejected Rate',
                dataIndex: 'rejectedRate',
                align: 'center',
                width: 150,
                key: '29',
            },
            {
                title: 'Pending Rate',
                dataIndex: 'pendingRate',
                align: 'center',
                width: 150,
                key: '30',
            },
        ],
    },
];

export const columnType = {
    1: viewColumns,
    2: categoryColumns,
};
